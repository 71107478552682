<template>
  <div>
    <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true">
        </loading>
    <b-container v-if="!isLoading" class="pb-5">
      <b-row>
        <b-col>
          <div class="banner-container">
            <img class="banner-img" :src="background_pics" style="object-position: bottom;" />
            <div class="avata-container">
              <a href="#" @click.prevent="openProfileGallery">
                <img :src="portrait_pic" style='width: 100%;'/>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="content">
        <b-col cols="12">
          <h4 class="title">
            {{ title_name }}
          </h4>
          <div class="sub-title mb-4">
            <div class="desc-date" v-if="dates">{{ dates }}</div>
            <div class="desc-service" v-if="true">{{ interred }}</div>
          </div>
          <div class="description">
          </div>
        </b-col>
        <b-col cols="12" lg="8" order='0' v-if="widescreen == 'mobile'">
          <div class="description">
            <p v-for="(o,i) in obit" :key="o+'fdsafd'+i">
              <template v-if="render_html">
                <span v-html="o"></span>
              </template>
              <template v-else>
                {{ handleNull(sanitize(o)) }}
              </template>
              </p>
          </div>
        </b-col>
       <b-col cols="12" lg="4" v-if="widescreen== 'mobile'" >
          <div class="social-container">
            <h6>
              <a :href="maps_link" target="_blank">
                <font-awesome-icon icon="map" size="lg" />
                Get GPS Directions
              </a>
            </h6>
            <h6>
              <a :href="link_360" target="_blank">
                <font-awesome-icon icon="map-marker-alt" size="lg" />
                Ground Level 360° View
              </a>
            </h6>
            <div class="social-icons">
              <a :href="social.facebook" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" size="2x"/>
              </a>
              <a :href="social.twitter" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" size="2x"/>
              </a>
              <a :href="social.mail" target="_blank">
                <font-awesome-icon icon="envelope" size="2x" />
              </a>
            </div>
          </div>
          <div class="map-container">
            <GmapMap
              :center="location"
              :zoom="15"
              map-type-id="satellite"
              style="width: 100%; height: 300px;"
            >
              <GmapMarker :position="location" />
            </GmapMap>
          </div>
        </b-col>
        <b-col cols="12" lg="8" >
          <div class="description">
            <div v-if='widescreen == "desktop"'>
              <p v-for="(o,i) in obit" :key="o+';lkjf'+i">
                <template v-if="render_html">
                  <span v-html="o"></span>
                </template>
                <template v-else>
                  {{ handleNull(sanitize(o)) }}
                </template>
              </p>
            </div>
            <div class="memory-container mt-4">
              <b-button
                pill
                variant="light"
                class="btn-leave-memory"
                @click="isShowMemoryModal = true"
              >
                <span class="float-left">Leave a memory...</span>
                <span class="float-right">
                  <font-awesome-icon :icon="['fas', 'photo-video']" />
                  Photo/Video
                </span>
              </b-button>
              <div
                v-for="(memo, index) in memory_pics"
                :key="`memo-${index}`"
                class="memory-item">
                <div class="memory-desc">
                  <a
                    v-if="memo.medias.length > 0" 
                    href="#" @click="showMemoGallery(index)">
                    <img
                      v-if="memo.medias[memo.firstIndex].type == 'photo'"
                      :src="memo.medias[memo.firstIndex] ? memo.medias[memo.firstIndex].src : null"
                    />
                    <div
                      v-if="memo.medias[memo.firstIndex].type == 'video'"
                      class="embed-responsive embed-responsive-16by9"
                    >
                      <iframe class="embed-responsive-item" :src="memo.medias[memo.firstIndex].src"></iframe>
                    </div>
                  </a>
                  <h6 class="mt-2" v-if="memo.Author_First_Name">
                    <!-- Message from -->
                    {{ memo.author_title }}
                    <!-- {{ memo.Author_First_Name }} -->
                    <!-- {{ get_first_letter(memo.Author_Last_Name) }} -->
                  </h6>
                  <h5 v-if="memo.Memory_Title">
                    {{ handleNull(memo.Memory_Title) }}
                  </h5>
                  <p v-if="memo.Memory_Text">
                    <template v-if="render_html">
                      <span v-html="memo.Memory_Text"></span>
                    </template>
                    <template v-else>
                      {{ handleNull(sanitize(memo.Memory_Text)) }}
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
         <b-col cols="12" lg="4" v-if="widescreen == 'desktop'" >
          <div class="social-container">
            <h6>
              <a :href="maps_link" target="_blank">
                <font-awesome-icon icon="map" size="lg" />
                Get GPS Directions
              </a>
            </h6>
            <h6>
              <a :href="link_360" target="_blank">
                <font-awesome-icon icon="map-marker-alt" size="lg" />
                Ground Level 360° View
              </a>
            </h6>
            <div class="social-icons">
              <a :href="social.facebook" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook']" size="2x"/>
              </a>
              <a :href="social.twitter" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" size="2x"/>
              </a>
              <a :href="social.mail" target="_blank">
                <font-awesome-icon icon="envelope" size="2x" />
              </a>
            </div>
          </div>
          <div class="map-container">
            <GmapMap
              :center="location"
              :zoom="15"
              map-type-id="satellite"
              style="width: 100%; height: 300px;"
            >
              <GmapMarker :position="location" />
            </GmapMap>
            <p class="version-info">F-v1.0.19 - B-v{{ version }}</p>
          </div>
        </b-col>
      </b-row>
      <CoolLightBox 
        :items="currentMemory" 
        :index="memoIndex"
        @close="memoIndex = null">
      </CoolLightBox>
      <b-modal v-model="isShowMemoryModal" title="Memory Information" size="lg" hide-footer>
        <MemoryForm @close="isShowMemoryModal = false" :api="api" :person_id="person_id"/>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import MemoryForm from '@/components/MemoryForm'
import APIClient from '@/plugins/api-client'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import urlParser from "js-video-url-parser";
import embedVideo from "embed-video";



export default {
  name: 'Home',
  components: {
    MemoryForm,
    Loading,
    CoolLightBox,
  },
  data: () => ({
    isShowGallery: false,
    memoIndex: null,
    memoFile: null,
    isShowMemoryModal: false,
    commentText: "",
    person_id: null,
    initial_data_types: [
      'get_user_media',
      'get_obituaries',
      'get_portraits',
      'get_background',
      'get_contact_info',
      'get_mem_posts',
      'get_lat_long',
      'get_cem360'],
    cache: {},
    isLoading: true,
    currentMemory: [],
    windowWidth: 0,
    api: null,
    render_html: false,
    version: "V1.0",
    months : {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
  },
  }),

  computed: {
    widescreen () {
      return this.windowWidth > 991 ? 'desktop' : 'mobile'
    },
    location () {
      if (this.isLoading) return { lat: 42.289514, lng: -71.178661 }
      let loc = { lat: 42.289514, lng: -71.178661 }
      try {
        let filt = _.filter(this.cache, o => o.type === 'get_lat_long')?.[0]?.data?.[0]
        loc.lat = filt?.Latitude && !isNaN(parseFloat(filt.Latitude)) ? parseFloat(filt.Latitude) : loc.lat
        loc.lng = filt?.Longitude && !isNaN(parseFloat(filt.Longitude)) ? parseFloat(filt.Longitude) : loc.lng
        return loc
      }
      catch {
        return { lat: 42.289514, lng: -71.178661 }
      }
    },
    loc_360 () {
      // Old default link: https://api.cemetery360.com/player/?ath=130&atv=0&fov=130&lat=42.289514&long=-71.178661
      if (this.isLoading) return { cem360link: `https://cemetery360.com/player/?lat=42.2873580761654&long=-71.1795177035815` }
      let loc = { cem360link: `https://cemetery360.com/player/?lat=42.2873580761654&long=-71.1795177035815`}
      try {
        let filt = _.filter(this.cache, o => o.type === 'get_cem360')?.[0]?.data?.[0]
       loc.cem360link = filt?.CEM360
       if (loc.cem360link.toLowerCase() == 'null' || loc.cem360link == '' || this.handleNull(loc.cem360link) == '') loc.cem360link = `https://cemetery360.com/player/?lat=42.2873580761654&long=-71.1795177035815`
        return loc
      }
      catch {
        return { cem360link: `https://cemetery360.com/player/?lat=42.2873580761654&long=-71.1795177035815` }
      }
    },
    maps_link () {
      if (this.isLoading) return "";
      return `https://www.google.com/maps/dir/?api=1&destination=${this.location.lat},${this.location.lng}`
    },
    link_360 () {
      if (this.isLoading) return "";
      return `${this.loc_360.cem360link}`
    },
    interred () {
      if (this.isLoading || !this.contact_info) return ''
      return ''
    },
    dates () {
      if (this.isLoading || !this.contact_info) return ''
      let base = ''
      if (this.handleNull(this.contact_info.birth_date)) {
        base = base + this.date_ff(this.contact_info.birth_date)
        if (this.handleNull(this.contact_info.death_date)) base = base + ' - '
      }
      if (this.handleNull(this.contact_info.death_date)) {
        base = base + this.handleNull(this.date_ff(this.contact_info.death_date))
      }
      return base
    },

    
    url () {
      if (this.isLoading) return "";
      return window.location.href
    },
    social () {
      return {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=' + this.url,
        twitter: 'http://twitter.com/share?url=' + this.url,
        mail: 'mailto:?subject=' + this.url
      }
    },
    contact_info () {
      if (this.isLoading) return [];
      return this.format_contact_info(_.filter(this.cache, o => o.type === 'get_contact_info')[0].data[0]);
    },
    title_name () {
      if (this.isLoading) return "";
      let base = "The Life of "
      if (this.contact_info.full_name) {
        base = base + this.contact_info.full_name
      }
      else if (this.contact_info.first_name) {
        base = base + this.titleCase(this.contact_info.first_name) + this.titleCase(this.contact_info.last_name)
      }
      else {
        /** has no first name, should never happen for title but leave blank */
        base = ""
      }
      document.title = "The Gardens at Gethsemane" + base
      return base
    },

    obit () {
      if (this.isLoading) return [];
      try {
        return _.filter(this.cache, o => o.type === 'get_obituaries')[0].data[0].OBIT.split('\n')
      }
      catch (e) {
        return ['']
      }
    },

    background_pics () {
      if (this.isLoading) return [];
      try {
        if (_.filter(this.cache, o => o.type === 'get_background')[0].data.filter(d => d.Is_Approved == 1).map(p => p.DocPath)[0]) return _.filter(this.cache, o => o.type === 'get_background')[0].data.filter(d => d.Is_Approved == 1).map(p => p.DocPath)[0]
        return '/img/bg.jpeg'
      }
      catch {
        return '/img/bg.jpeg'
      }
    },
    portrait_pics () {
      if (this.isLoading) return [];
      return _.filter(this.cache, o => o.type === 'get_portraits')[0].data.map(p => p.DocPath).map((v,i,) => this.format_gallery(v,`Picture ${i+1}`));
    },
    portrait_pic () {
      if (!this.portrait_pics.length) return null;
      return this.portrait_pics[0] ? this.portrait_pics[0].src : ''
    },
    
    
    memory_pics () {
      if (this.isLoading) return [];
      try {
        let memories =  _.filter(this.cache, o => o.type === 'get_mem_posts')[0].data;
        let medias =  _.filter(this.cache, o => o.type === 'get_user_media')[0].data;
        return memories.map(item => {
          const curId = item.Mem_PostID
          let curMediaList = []
          let orig
          medias.forEach(element => {
            if (element.Mem_PostID.includes(curId)) {
              const curType = element.DocPath.includes('.s3.') ? 'photo' : 'video'
              if (!element.DocPath || element.DocPath == '') return
              let newMedia = {
                src: element.DocPath,
                type: curType,
              }
              if (curType == 'video') {
                orig = newMedia.src
                newMedia.src = this.modifyUrl(newMedia.src)
              }
              curMediaList.push(newMedia)
            }
          })
          let firstIndex = curMediaList.findIndex((val) => val.type === 'photo')
          if (firstIndex === -1 && curMediaList.length > 1 && curMediaList.every(i => i.type == 'video')) {
            firstIndex = 0
            /** add a thumnail photo of first video */
            try {
              let type 
              if (curMediaList[0].src.includes("youtube")) type = 'sddefault'
              else if (curMediaList[0].src.includes("vimeo")) type = 'thumbnail_large'
              else if (curMediaList[0].src.includes("dailymotion")) type = 'thumbnail_720_url'
              embedVideo.image(orig, {image: type}, (err, thumbnail)  => {
                if (!err) {
                  curMediaList.unshift({
                    src: "https:" + thumbnail.src,
                    type: 'photo',
                    delete: true
                  })
                  this.$forceUpdate()
                }
              })
            } catch (e) {
              e
            }
          }
          if (curMediaList.length) curMediaList.unshift(curMediaList.splice(firstIndex, 1)[0])
          firstIndex = 0
          return {
            ..._.transform(item, (memo, value, key) => memo[key] = _.trim(value), {}),
            author_title: (item.Author_First_Name && item.Author_First_Name.toLowerCase() != "null" && item.Author_First_Name != ' ') ? `Message from ${this.titleCase(item.Author_First_Name)} ${this.lastName(item.Author_Last_Name)}` : "",
            medias: curMediaList,
            firstIndex: firstIndex > -1 ? firstIndex : 0,
            comment_text: ""
          }
        })
      }
      catch (e) {
        return [];
      }
      }
  },

  methods: {
    lastName(txt) {
      return txt ? txt.charAt(0).toUpperCase() + "." : ""
    },
    titleCase(txt) {
      return txt ? txt.toLowerCase()
                      .split(' ')
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' ')
                 : ""
    },
    sanitize(s, space = 1) {
      try {
        var span = document.createElement('span');
        span.innerHTML= s;
        if(space) {
          var children= span.querySelectorAll('*');
          for(var i = 0 ; i < children.length ; i++) {
            if(children[i].textContent)
              children[i].textContent+= ' ';
            else
              children[i].innerText+= ' ';
          }
        }
        if ([span.textContent || span.innerText].toString()) return [span.textContent || span.innerText].toString().replace(/ +/g,' ')
      return "";
      }
      catch (e) {
        e
        return ""
      }
    },
    showMemoGallery(memo) {
      this.memoIndex = null
      this.currentMemory = this.memory_pics[memo].medias.filter(i => !i?.delete).map((item) => item ? item.src : null)
      this.memoIndex = 0
    },
    format_contact_info(data) {
      return {
      person_id: this.person_id,
      first_name: data ? this.handleNull(data.First_Name) : ' ',
      middle_name: data ? this.handleNull(data.Middle_Name) : ' ',
      last_name: data ? this.handleNull(data.Last_Name) : ' ',
      suffix: data ? this.handleNull(data.Suffix) : ' ',
      street_address1: data ? this.handleNull(data.Street_Address1) : ' ',
      street_address2: data ? this.handleNull(data.Street_Address2) : ' ',
      city: data ? this.handleNull(data.City) : ' ',
      state: data ? this.handleNull(data.State) : ' ',
      zipcode: data ? this.handleNull(data.Zipcode) : ' ',
      full_name: data ? this.handleNull(data.Full_Name) : ' ',
      birth_date: data ? this.handleNull(data.D_Of_Birth) : ' ',
      death_date: data ? this.handleNull(data.D_Of_Death) : ' ',
      birth_place: data ? this.handleNull(data.Place_Of_Birth) : ' ',
    }
    },

    get_first_letter(string) {
      return string ? (string.length ? string[0] + '.' : "") : "";
    },
    format_gallery(image, name) {
      return { thumb: image, src: image, caption: name }
    },
    nth(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    },
    date_ff (date) {
      if (!date || date == 'null') return ''
      let d = new Date(date);
      return `${this.months[d.getMonth()]} ${d.getDate()}${this.nth(d.getDate())} ${d.getFullYear()}`
    },

    c (s) { return _.trim(s ? s.toLowerCase() : "") },
    
    name_f (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },

    openProfileGallery() {
      this.memoIndex = null
      this.currentMemory = this.portrait_pics.map((item) => item ? item.src : null)
      this.memoIndex = 0
    },
    async send_email () {
      if (!this.email) return "Need an email";
      this.author = {};
      await this.api.make_request({ type: 'send_mail', author_first_name: this.author.first_name, email: this.author.email });
    },
    handleNull(txt) {
      if (txt && txt.toLowerCase() != 'null' && _.trim(txt) != '') return txt
      else return ''
    },
    async envVars() {
      this.api.version()
              .then((version)=> this.version = version)
              .catch((e) => (e))
      this.api.render_html()
              .then((render_html)=> this.render_html = render_html)
              .catch((e) => (e))
    },
    async load_page() {
      this.isLoading = true;
      this.api = new APIClient();
      this.envVars()
      try {
        const temp = await Promise.all([...this.initial_data_types.map(req_type => this.api.make_request({ type: req_type, person_id: this.person_id })), ]);
        this.cache = temp.map(t => ({ type: t.type, data: t.data.recordset }));
        if (this.cache.every(c => !(c.data.length > 0))) {
          window.location = 'https://thegardenscemetery.org/looking-for-a-loved-one/' // redirect if no data available
          return
        }
      } catch(e) {
        e
      }
      this.isLoading = false;
    },
    getString(str, limit) {
      if (str) {
        if (str.length > limit) {
          return str.substring(0, limit)
        }
      }
      return str
    },
    modifyUrl(url) {
      if (!url) return ""
      let parsed = urlParser.parse(url)
      if (parsed) {
        if (parsed.provider == 'vimeo') {
          return `https://player.vimeo.com/video/${parsed.id}`
        }
        else if (parsed.provider == 'youtube') {
          return `https://www.youtube.com/embed/${parsed.id}`
        }
        else if (parsed.provider == 'dailymotion') {
          return `https://www.dailymotion.com/embed/video/${parsed.id}`
        }
      }
      if (url.includes("youtu.be")) {
        let id = /\s*[a-zA-Z//:.]*youtu(be.com\/watch\?v=|.be\/)([a-zA-Z0-9\-_]+)([a-zA-Z0-9/*-_?&;%=.]*)/.exec(url)
        if (id[2]) {
          return `https://www.youtube.com/embed/${id[2]}`
        }
      }
      if (url.includes("vimeo") && url.includes("review")) {
        let id = /\/(\d{9})\//.exec(url)
        if (id[1]) {
          return `https://player.vimeo.com/video/${id[1]}`
        }
        return "url"
      }
      let endpoint = url;
      endpoint = endpoint.replace('oembed', 'iframe');
      endpoint = endpoint.replace('url', 'src');
      endpoint = endpoint.replace('watch?v=', 'embed/');
      endpoint = endpoint.replace('oembed', 'iframe');
      return endpoint;
    },
  }, 
  created() {
    if (!this.$route.query.person_id) window.location = 'https://thegardenscemetery.org/looking-for-a-loved-one/'
    document.title = "The Gardens at Gethsemane"
    this.person_id = this.$route.query.person_id; // need person_id
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    this.windowWidth = this.$el.clientWidth+15
    this.load_page();
  },
}
</script>
<style lang="scss">
  .banner-container {
    position: relative;
    width: 100%;
    .banner-img {
      width: 100%;
      max-height: 350px;
      object-fit: cover;

    }
    .avata-container {
      position: absolute;
      height: 200px;
      width: 200px;
      bottom: -100px;
      right: 100px;
      border-radius: 200px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        
      }
      @media only screen and (max-width: 767.99px) {
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: 50px;
      }
      @media only screen and (max-width: 576.99px) {
        width: 100px;
        height: 100px;
        bottom: -50px;
        right: 10px;
      }
    }
  }
  .content {
    margin-top: 1.5rem;
    @media only screen and (max-width: 576.99px) {
      margin-top: 3rem;
    }
    .description {
      text-align: justify;
    }
  }
  .memory-container {
    .btn-leave-memory {
      width: 100%;
    }
    .custom-file {
      
      .custom-file-label {
        border-radius: 100px;
        .form-file-text {
          font-weight: 600;
        }
      }
    }
    .memory-item {
      border: 1px solid #dee2e6 !important;
      margin-top: 2rem;
      margin-bottom: 2rem;
      img {
        width: 100%;
      }
      .memory-desc {
        padding: 1rem;
      }
    }
  }
  .social-container {
    .fa-map,
    .fa-map-marker-alt {
      width: 40px;
    }
    .social-icons {
      margin-top: 2rem;
      a {
        margin: 0.5rem;
      }
      .fa-facebook {
        color: #3F5A97;
      }
      .fa-twitter {
        color: #4EA7DE;
      }
      .fa-google-plus {
        color: #D93F2C;
      }
      .fa-envelope {
        color: #588AD6;
      }
    }
  }
  .map-container {
    margin: 2rem 0;
    .version-info{
      font-size: .75rem;
      color: #4f4e4e;
      text-align: right;
    }
  }
</style>